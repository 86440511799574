import { signOut, sendPasswordResetEmail } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions';

export const logOut = (auth) => {
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
};

export const resetPassword = async (auth) => {
    var emailAddress = auth.currentUser.email;
    return await sendPasswordResetEmail(auth, emailAddress).then(() => {
        return true;
    }).catch((error) => {
        return false;
    });
}

export const getStripePortalURL = async (firebaseApp) => {
    const functions = getFunctions(firebaseApp);
    const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    return await functionRef({ returnUrl: window.location.origin }).then((result) => {
        return result?.data?.url;
    }).catch((error) => {
        return null;
    });
};