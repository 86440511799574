
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AccountDrawer from '../Components/AccountDrawer/AccountDrawer';
import { AuthContext } from '../Context/AuthProvider';

export const TopBar = () => {
    const auth = React.useContext(AuthContext);

    const isFAQ = () => {
        return window.location.pathname === '/faq'
    };
  
    return (
        <>
            <AppBar position="fixed" elevation={0}>
                <Toolbar style={{display: "flex", justifyContent: "space-between", backgroundColor: "#334047"}}>
                    <div>
                        {auth?.user && <div style={{width:"28px"}}/>}
                    </div>
                    <div style={{cursor: isFAQ() ? 'pointer' : 'auto'}} onClick={()=>{
                        if (isFAQ()) {
                            window.location.href = '/'
                        }
                    }}>
                        <img src={process.env.PUBLIC_URL + '/Logo.png'} alt="Lotus Book Art" style={{maxWidth: "50px", maxHeight: "50px"}} />
                    </div>
                    <div>
                        {auth?.user && <AccountDrawer/>}
                    </div>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    );
};