import React from "react";
import { initializeApp } from "firebase/app";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyC6EpaFfeOVzm0vcg8lq_sJOt7oxMG2vaE",
    authDomain: "lotus-book-image.firebaseapp.com",
    projectId: "lotus-book-image",
    storageBucket: "lotus-book-image.appspot.com",
    messagingSenderId: "912366509904",
    appId: "1:912366509904:web:e8018f4cc01534d26bd655",
    measurementId: "G-HK9JDCDFB5"
};
const app = initializeApp(firebaseConfig);

export const FirebaseAppContext = React.createContext();

export const FirebaseAppProvider = (props) => {
    return (
        <FirebaseAppContext.Provider value={app}>
            {props.children}
        </FirebaseAppContext.Provider>
    );
};