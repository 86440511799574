import * as React from 'react';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';

const SignInUp = () => {
    const [page, setPage] = React.useState('SignUp');
    const switchPage = () => {
        switch (page) {
            case 'SignUp':
            default:
                setPage('SignIn');
                break;
            case 'SignIn':
                setPage('SignUp');
                break;
        }
    };
    const getPage = () => {
        switch (page) {
            case 'SignUp':
            default:
                return <SignUp onLinkClick={()=>{switchPage()}}/>
            case 'SignIn':
                return <SignIn onLinkClick={()=>{switchPage()}}/>
        }
    };

    return getPage();
};

export default SignInUp;