import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from '@mui/material/Container';
import { SnackbarProvider } from './Context/SnackBarProvider';
import { AuthProvider } from './Context/AuthProvider';
import { FirebaseAppProvider } from './Context/FirebaseAppContext';
import { TopBar } from './Components/TopBar';
import { SignedInCheck } from './Components/SignInUp/SignedInCheck';
import { Faq } from './Components/Faq';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="*" element={<Main />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </BrowserRouter>
  );
}

const Main = () => {
  return (
    <FirebaseAppProvider>
      <AuthProvider>
        <SnackbarProvider>
          <TopBar />
          <Container style={{marginTop: "16px"}}>
            <SignedInCheck />
          </Container>
        </SnackbarProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  );
};

export default App;
