import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { createUserWithEmailAndPassword } from "firebase/auth";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import GroupsIcon from '@mui/icons-material/Groups';
import Box from '@mui/material/Box';
import { SnackbarContext } from '../../Context/SnackBarProvider';
import { AuthContext } from '../../Context/AuthProvider';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const SignUp = (props) => {
    const {onLinkClick} = props;
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const benefits = [
        "Turn old books into art",
        "Put any image on any book",
        "Create unlimited easy to follow patterns",
        "Create great gifts for any occasion",
        "Patterns save on ink"];
    const [activeImageStep, setActiveImageStep] = React.useState(0);
    const {setSnackbar} = React.useContext(SnackbarContext);
    const { auth } = React.useContext(AuthContext);

    const images = {
        originals: [
            {
                label: 'Sun flower image',
                imgPath:
                process.env.PUBLIC_URL + '/Image1.jpg',
            },
            {
                label: 'Cat',
                imgPath:
                process.env.PUBLIC_URL + '/Image2.jpg',
            }
        ],
        books: [
            {
                label: 'Sun flower book',
                imgPath:
                process.env.PUBLIC_URL + '/Book1.jpg',
            },
            {
                label: 'Cat',
                imgPath:
                process.env.PUBLIC_URL + '/Book2.jpg',
            }
        ]
    };
    const handleImageStepChange = (step) => {
        setActiveImageStep(step);
    };

    const onSignUpClick = () => {
        createUserWithEmailAndPassword(auth, email.trim(), password)
        .then((userCredential) => {
            const user = userCredential.user;
            setSnackbar({
                text: "Welcome!",
                severity: "success",
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message.replace('Firebase: ', '');
            setSnackbar({
                text: errorMessage,
                severity: "error",
            });
        });
    };

    return (
        <div style={{borderRadius: "25px", backgroundColor: "#FEFEFF", padding: "16px", display: "flex", alignItems: "center", flexDirection: "column", gap: "10px"}}>

            <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "nowrap", maxHeight: 300}}>
                <AutoPlaySwipeableViews
                    axis="x"
                    index={activeImageStep}
                    onChangeIndex={handleImageStepChange}
                    style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {images.originals.map((step, index) => (
                        <div key={step.label} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            {Math.abs(activeImageStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: 300,
                                    diplay: 'block',
                                    overflow: 'hidden'}}
                                src={step.imgPath}
                                alt={step.label}
                            />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <AutoPlaySwipeableViews
                    axis="x"
                    index={activeImageStep}
                    onChangeIndex={handleImageStepChange}
                    style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {images.books.map((step, index) => (
                        <div key={step.label} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            {Math.abs(activeImageStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: 300,
                                    diplay: 'block',
                                    overflow: 'hidden'}}
                                src={step.imgPath}
                                alt={step.label}
                            />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
            </div>
            
            <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap-reverse"}}>

                <div style={{display: "flex", flexDirection: "column", minHeight: "300px", justifyContent:"center", alignItems: "center"}}>

                    <Typography variant="h4" gutterBottom component="div">
                        Create Account
                    </Typography>
                    <TextField
                        style={{marginBottom: "15px", width: "200px"}}
                        required
                        label="Email"
                        variant="standard"
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}/>
                    <TextField
                        style={{marginBottom: "15px", width: "200px"}}
                        required
                        label="Password"
                        variant="standard"
                        type="password"
                        value={password}
                        onChange={(e)=>{setPassword(e.target.value)}}/>
                    <Button
                        style={{marginBottom: "15px"}}
                        variant="contained"
                        size="large"
                        color="success"
                        onClick={onSignUpClick}>
                        Sign Up
                    </Button>
                    <Link onClick={onLinkClick}>Already have an account?</Link>
                </div>

                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                        {benefits.map((item)=>{
                            return (
                                <List dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleIcon color="success" />
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                </List>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div style={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={()=>{window.location.href="https://www.facebook.com/ForeEdgeBookArt/"}}>
                <FacebookIcon fontSize="large" sx={{ color: "#4267B2" }} />
                <Typography style={{marginLeft: "10px"}}>Like us on Facebook</Typography>
            </div>
            
            <div style={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={()=>{window.location.href="https://www.facebook.com/groups/385065887790972/"}}>
                <GroupsIcon fontSize="large" sx={{ color: "#4267B2" }} />
                <Typography style={{marginLeft: "10px"}}>Join the community</Typography>
            </div>
            
        </div>
    );
};