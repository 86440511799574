import * as React from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const [user, setUser] = React.useState();
    const auth = getAuth();

    onAuthStateChanged(auth, (thisUser) => {
        if (thisUser) {
            setUser(thisUser);
        } else {
            setUser(null);
        }
    });

    return (
        <AuthContext.Provider value={{auth, user}}>
            {props.children}
        </AuthContext.Provider>
    );
};