import React, { useRef } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ImageBrowseButton = (props) => {
    const { callback, imageDataURL, id } = props;
    const fileInput = useRef(null);

    const handleChange = (event) => {
        event.preventDefault();
        var reader = new FileReader();

        const file = fileInput.current.files[0]

        if (!typeof file === 'Blob') {
            alert("Please upload an image file.");
            return;
        }

        if (file.type === 'image/gif') {
            alert("GIFs are not supported. Please upload a different image type.");
            return;
        }

        reader.readAsDataURL(fileInput.current.files[0]);
        reader.onload = function (e) {
            const dataURL = e.target.result;
            callback(dataURL);
        };
    }

    return (
        <>
        <input
            accept="image/*"
            style={{ display: "none" }}
            id={id}
            type="file"
            ref={fileInput}
            onChange={handleChange}
        />
        <label htmlFor={id}>
            <Button
                component="span"
                variant="contained"
                size="large"
                color="primary"
                endIcon={<CloudUploadIcon />}>
                Browse
            </Button>
        </label>
        {imageDataURL && 
          <img
            src={imageDataURL}
            alt=""
            style={{maxHeight: "250px", maxWidth: "300px", margin: "16px"}}
          />}
        </>
    );
}

export default ImageBrowseButton;
