import {useContext, useState, useEffect} from 'react';
import { FirebaseAppContext } from '../../Context/FirebaseAppContext';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CircularProgress from '@mui/material/CircularProgress';
import { getStripePortalURL } from '../../js/account';

export const ManageSubscription = (props) => {
    const {callBack} = props;
    const [url, setURL] = useState(null);
    const firebaseApp = useContext(FirebaseAppContext);

    useEffect(()=>{
        const getURL = async () => {
            const url = await getStripePortalURL(firebaseApp);
            setURL(url);
        };
        getURL();
    }, [firebaseApp]);

    const onManageSubscriptionClick = async () => {
        callBack();
        window.location.assign(url);
    };

    if (url === null) {
        return (
            <ListItem key={"Subscription"} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <CircularProgress size={20} />
                    </ListItemIcon>
                    <ListItemText primary={"Manage subscription"} />
                </ListItemButton>
            </ListItem>
        );
    } else if (url === undefined) {
        return null;
    } else {
        return (
            <ListItem key={"Subscription"} disablePadding>
                <ListItemButton onClick={()=>{onManageSubscriptionClick()}}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Manage subscription"} />
                </ListItemButton>
            </ListItem>
        );
    }
};