import React, { useContext } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LockResetIcon from '@mui/icons-material/LockReset';
import { resetPassword } from '../../js/account';
import { SnackbarContext } from '../../Context/SnackBarProvider';
import { AuthContext } from '../../Context/AuthProvider';

export const PasswordReset = (props) => {
    const { callBack } = props;
    const { setSnackbar } = useContext(SnackbarContext);
    const { auth } = React.useContext(AuthContext);

    const onClick = async () => {
        const success = await resetPassword(auth);
        if (success) {
            callBack();
            setSnackbar({
                text: "Password reset email sent",
                severity: "success",
            });
        } else {
            setSnackbar({
                text: "Error sending password reset email",
                severity: "error",
            });
        }
    }
    return (
        <ListItem key={"PasswordReset"} disablePadding>
            <ListItemButton onClick={()=>{onClick()}}>
                <ListItemIcon>
                    <LockResetIcon />
                </ListItemIcon>
                <ListItemText primary={"Reset password"} />
            </ListItemButton>
        </ListItem>
    );
};