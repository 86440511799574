import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../Context/AuthProvider';
import Experience from '../Experience/Experience';
import SignInUp from './SignInUp';

export const SignedInCheck = () => {
    const { user } = React.useContext(AuthContext);
  
    if (user === undefined) {
      return (<div style={{display: "flex", justifyContent: "center"}}>
        <CircularProgress />
      </div>);
    }
    if (user) {
      return <Experience />;
    }
    return <SignInUp />;
  }