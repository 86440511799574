import * as React from 'react';
import Button from '@mui/material/Button';
import Stepper from '../Stepper';
import Typography from '@mui/material/Typography';
import ImageBrowseButton from '../ImageBrowseButton';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import TuneIcon from '@mui/icons-material/Tune';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import { isMobile } from 'react-device-detect';
import GetPattern from '../../js/PatternBuilder';
import { SubscribedCheck, Subscribe } from './Subscribe';
import { ANGLE_LINES, IMAGETYPE, BOOK_INFO_DEFAULTS, MEASUREMENT_UNITS, FRACTIONS } from '../../js/constants';

function Experience() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [imagePrimaryDataURL, setImagePrimaryDataURL] = React.useState(null);
  const [imageSecondaryDataURL, setImageSecondaryDataURL] = React.useState(null);
  const [imageType, setImageType] = React.useState(IMAGETYPE.SINGLE);
  const [lastOddPage, setLastOddPage] = React.useState(249);
  const [height, setHeight] = React.useState(220);
  const [angleLine, setAngleLine] = React.useState(ANGLE_LINES.NONE);
  const [isInkSaver, setIsInkSaver] = React.useState(false);
  const [measurementUnit, setMeasurementUnit] = React.useState(MEASUREMENT_UNITS[0].value);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    window.location.reload();
  };

  const renderStep = () => {
    let step;
    switch (activeStep) {
      case 0:
      default:
        step = (<BrowseImage onNextClick={handleNext} imagePrimaryDataURL={imagePrimaryDataURL} setImagePrimaryDataURL={setImagePrimaryDataURL} imageSecondaryDataURL={imageSecondaryDataURL} setImageSecondaryDataURL={setImageSecondaryDataURL} imageType={imageType} setImageType={setImageType} isInkSaver={isInkSaver} setIsInkSaver={setIsInkSaver} angleLine={angleLine} setAngleLine={setAngleLine} />);
        break;
      case 1:
        step = (<BookDetails onNextClick={handleNext} onBackClick={handleBack} lastOddPage={lastOddPage} height={height} setLastOddPage={setLastOddPage} setHeight={setHeight} measurementUnit={measurementUnit} setMeasurementUnit={setMeasurementUnit} />);
        break;
      case 2:
        step = (<PrintPattern onResetClick={handleReset} onBackClick={handleBack} imagePrimaryDataURL={imagePrimaryDataURL} imageSecondaryDataURL={imageType === IMAGETYPE.DOUBLE ? imageSecondaryDataURL : null} height={height} lastOddPage={lastOddPage} isInkSaver={isInkSaver} angleLine={angleLine} />);
        break;
    }
    return (
      step
    );
  }

  const renderTitle = () => {
    let title;
    switch (activeStep) {
      case 0:
      default:
        title = "Let's start by uploading an image";
        break;
      case 1:
        title = "Great! Now let's get some book info";
        break;
      case 2:
        title = "You're almost done!";
        break;
    }
    return (
      <div style={{display: "flex", justifyContent: "center", padding: "20px"}}>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Stepper activeStep={activeStep} />
      {renderTitle()}
      {renderStep()}
    </>
  );
}

const BrowseImage = (props) => {
  const {onNextClick, imageType, setImageType, imagePrimaryDataURL, setImagePrimaryDataURL, imageSecondaryDataURL, setImageSecondaryDataURL, isInkSaver, setIsInkSaver, angleLine, setAngleLine} = props;
  
  const [showAdvancedModal, setShowAdvancedModal] = React.useState(false);
  const onShowAdvancedClick = () =>{
    setShowAdvancedModal(true);
  }
  const AdvancedSettingsModal = () => {
    // Ink saver
    const [anchorInfoInkSaver, setAnchorInfoInkSaver] = React.useState(null);
    const openPopoverInkSaver = Boolean(anchorInfoInkSaver);
    const inkSaverPopupId = openPopoverInkSaver ? 'ink-saver-popover' : undefined;
    const handleInfoInkSaverClick = (event) => {
      setAnchorInfoInkSaver(event.currentTarget);
    };
    const handleInfoInkSaverClose = () => {
      setAnchorInfoInkSaver(null);
    };
    const handleInkSaverChange = (event) => {
      setIsInkSaver(event.target.checked);
    };

    //Angle line
    const [anchorInfoAngleLine, setAnchorInfoAngleLine] = React.useState(null);
    const openPopoverAngleLine = Boolean(anchorInfoAngleLine);
    const angleLinePopupId = openPopoverAngleLine ? 'angle-line-popover' : undefined;
    const handleInfoAngleLineClick = (event) => {
      setAnchorInfoAngleLine(event.currentTarget);
    };
    const handleInfoAngleLineClose = () => {
      setAnchorInfoAngleLine(null);
    };
    const handleAngleLineChange = (event) => {
      setAngleLine(event.target.value);
    };

    return (
      <>
        {showAdvancedModal && 
        <Modal
          open={showAdvancedModal}
          onClose={() => setShowAdvancedModal(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}
        >
          <ModalDialog layout="center" sx={{backgroundColor: 'transparent', border: 'none', boxShadow: 'none', width: '-webkit-fill-available'}} >
            <Sheet
              variant="soft"
              sx={{
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
              }}
            >
              <ModalClose variant="plain" sx={{ m: 1 }} />
              <Typography gutterBottom>
                Advanced Settings
              </Typography>

              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                
                <FormControl>
                  <FormLabel>Pattern type</FormLabel>
                  <RadioGroup
                    value={imageType}
                    onChange={handleImageTypeChange}
                  >
                    <FormControlLabel value={IMAGETYPE.SINGLE} control={<Radio />} label="Single Image" />
                    <FormControlLabel value={IMAGETYPE.DOUBLE} control={<Radio />} label="Lenticular" />
                  </RadioGroup>
                </FormControl>

                <div style={{flexGrow: 1}}/>

                <FormControl>
                  <div>
                    <FormControlLabel control={<Switch 
                      checked={isInkSaver}
                      onChange={handleInkSaverChange}/>} label="Ink Saver" />
                    <InfoOutlinedIcon onClick={handleInfoInkSaverClick} fontSize="xsmall" />
                  </div>
                  
                  <Popover
                    id={inkSaverPopupId}
                    open={openPopoverInkSaver}
                    anchorEl={anchorInfoInkSaver}
                    onClose={handleInfoInkSaverClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Typography sx={{ p: 1 }}>Use thinner strips to save on ink</Typography>
                  </Popover>
                </FormControl>

                <div style={{flexGrow: 1}}/>

                <FormControl>
                  <div>
                    <FormControlLabel size="small" control={
                      <>
                        <Select
                          variant="standard"
                          value={angleLine}
                          label="Angle Line"
                          onChange={handleAngleLineChange}
                        >
                          <MenuItem value={ANGLE_LINES.NONE}>{ANGLE_LINES.NONE}</MenuItem>
                          <MenuItem value={ANGLE_LINES.TOPONLY}>{ANGLE_LINES.TOPONLY}</MenuItem>
                          <MenuItem value={ANGLE_LINES.BOTTOMONLY}>{ANGLE_LINES.BOTTOMONLY}</MenuItem>
                          <MenuItem value={ANGLE_LINES.TOPANDBOTTOM}>{ANGLE_LINES.TOPANDBOTTOM}</MenuItem>
                        </Select>
                      </>
                    } label=" 45° cut lines" />
                    <InfoOutlinedIcon onClick={handleInfoAngleLineClick} fontSize="xsmall" />
                  </div>
                  
                  <Popover
                    id={angleLinePopupId}
                    open={openPopoverAngleLine}
                    anchorEl={anchorInfoAngleLine}
                    onClose={handleInfoAngleLineClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Typography sx={{ p: 1 }}>Show lines to guide angle cuts at the top and bottom of each strip</Typography>
                  </Popover>
                </FormControl>
              </div>
            </Sheet>
          </ModalDialog>
        </Modal>}
      </>
    );
  };

  const handleImageTypeChange = (event) => {
    setImageType((event.target).value);
  };

  const isNextButtonBisabled = () => {
    if (imageType === IMAGETYPE.SINGLE) {
      return imagePrimaryDataURL ? false : true;
    }
    //Double
    return imagePrimaryDataURL && imageSecondaryDataURL ? false : true;
  }

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", minHeight: "300px", borderRadius: "15px", backgroundColor: "#FEFEFF", padding: "16px"}} >
      
      <Button 
        variant="text" 
        size="small" 
        color="inherit"
        onClick={onShowAdvancedClick}
        endIcon={<TuneIcon />}
        style={{marginBottom: "20px"}}>
          Advanced Settings
      </Button>
      <AdvancedSettingsModal />

      <div style={{flexGrow: 1}}/>

      <div style={{display: "flex"}}>

        {/* Primary Image */}
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "300px"}}>
          <ImageBrowseButton id={'1'} imageDataURL={imagePrimaryDataURL} callback={setImagePrimaryDataURL}/>
        </div>

        {/* Secondary Image */}
        {imageType === IMAGETYPE.DOUBLE && 
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "300px"}}>
          <ImageBrowseButton id={'2'} imageDataURL={imageSecondaryDataURL} callback={setImageSecondaryDataURL}/>
        </div>}

      </div>

      <div style={{flexGrow: 1}}/>

      <Button variant="contained" style={{boxShadow: 'none'}} disabled={isNextButtonBisabled()} onClick={onNextClick}>
        {'Next'}
      </Button>
    </div>
  );
};

const BookDetails = (props) => {
  const {onNextClick, onBackClick, lastOddPage, height, setLastOddPage, setHeight, measurementUnit, setMeasurementUnit} = props;

  const getHeightCM = (heightMM) => {
    let heightCM = heightMM / 10;
    return String(parseFloat(heightCM.toFixed(1))).replace(/^0+/, '');
  }
  const getHeightInchPrimary = (heightMM) => {
    let heightInch = heightMM / 25.4;
    if (parseFloat(heightInch.toFixed(2) % 1) >= 0.97){
      return String(parseFloat(Math.ceil(heightInch)));
    }
    return String(parseFloat(Math.floor(heightInch)));
  }
  const getHeightInchFraction = (heightMM) => {
    const heightInch = heightMM / 25.4;
    const fractionDecimal = (heightInch % 1).toFixed(3);

    if (heightMM > BOOK_INFO_DEFAULTS.MAX_HEIGHTMM) {
      return FRACTIONS[3].value;
    }

    if (fractionDecimal >= FRACTIONS[0].value && fractionDecimal < parseFloat(FRACTIONS[1].value) - parseFloat(1/16)) {
      return FRACTIONS[0].value;
    } else if (fractionDecimal >= parseFloat(FRACTIONS[1].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[2].value) - parseFloat(1/16)) {
      return FRACTIONS[1].value;
    } else if (fractionDecimal >= parseFloat(FRACTIONS[2].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[3].value) - parseFloat(1/16)) {
      return FRACTIONS[2].value;
    } else if (fractionDecimal >= parseFloat(FRACTIONS[3].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[4].value) - parseFloat(1/16)) {
      return FRACTIONS[3].value;
    } else if (fractionDecimal >= parseFloat(FRACTIONS[4].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[5].value) - parseFloat(1/16)) {
      return FRACTIONS[4].value;
    } else if (fractionDecimal >= parseFloat(FRACTIONS[5].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[6].value) - parseFloat(1/16)) {
      return FRACTIONS[5].value;
    } else if (fractionDecimal >= parseFloat(FRACTIONS[6].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[7].value) - parseFloat(1/16)) {
      return FRACTIONS[6].value;
    } else if (fractionDecimal >= parseFloat(FRACTIONS[7].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[7].value) + parseFloat(1/16)) {
      return FRACTIONS[7].value;
    } else {
      return FRACTIONS[0].value;
    }
  }

  const onChangeMeasurementUnit = (e) => {
    setMeasurementUnit(e.target.value);
  };

  const Height = {
    value: height,
    valueCM: getHeightCM(height),
    valueInchPrimary: getHeightInchPrimary(height),
    valueInchFraction: getHeightInchFraction(height),
    onChangeCM: (e) => {
      const rounded = Math.round(e.target.value * 10) / 10
      const height = Number(rounded) * 10;
      setHeight(height);
    },
    onChangeInchPrimary: (e) => {
      const primary = Math.round(e.target.value);
      const fraction = getHeightInchFraction(height);
      const heightInch = primary + parseFloat(fraction);
      const heightMM = Math.round(heightInch * 25.4);
      setHeight(heightMM);
    },
    onChangeInchFraction: (e) => {
      const primary = Math.round(getHeightInchPrimary(height));
      const fraction = parseFloat(e.target.value);
      const heightInch = primary + fraction;
      const heightMM = Math.round(heightInch * 25.4);
      setHeight(heightMM);
    },
    isValidCM: () => {
        return height &&                                    //non-empty
        height >= 25 &&                                       //greater than 0
        height <= BOOK_INFO_DEFAULTS.MAX_HEIGHTMM &&        //less than 260
        height % 1 === 0 &&                                 //no decimals
        height.toString().length <= 5;                      //prevent many 0s
    },
    isValidInch: () => {
      const primary = Math.round(getHeightInchPrimary(height));
      const fraction = parseFloat(getHeightInchFraction(height));
      const sum = primary + fraction;
      return getHeightInchPrimary(height) &&                                    //non-empty
      getHeightInchPrimary(height) > 0 &&                                       //greater than 0
      sum <= BOOK_INFO_DEFAULTS.MAX_HEIGHTINCH &&                               //less than 10.25
      getHeightInchPrimary(height) % 1 === 0 &&                                 //no decimals
      getHeightInchPrimary(height).toString().length <= 5;                      //prevent many 0s
    },
    isValidInchFraction: () => {
      const primary = Math.round(getHeightInchPrimary(height));
      const fraction = parseFloat(getHeightInchFraction(height));
      const sum = primary + fraction;
      return sum <= BOOK_INFO_DEFAULTS.MAX_HEIGHTINCH                           //less than 10.25
    },
  };
  const LastOddPage = {
    value: lastOddPage,
    onChange: (e) => {
      setLastOddPage(Number(e.target.value).toString());
    },
    isValid: () => {
        return lastOddPage &&                                     //non-empty
        lastOddPage % 2 &&                                        //odd
        lastOddPage > 0 &&                                        //greater than 0
        lastOddPage <= BOOK_INFO_DEFAULTS.MAX_LAST_ODD_PAGE &&    //less than 5000
        lastOddPage % 1 === 0 &&                                  //no decimals
        lastOddPage.toString().length <= 5;                       //prevent many 0s
    },
  };

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", minHeight: "300px", borderRadius: "15px", backgroundColor: "#FEFEFF", padding: "16px"}}>
      
      <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
        <img src={process.env.PUBLIC_URL + '/Height.png'} alt="" style={{height: "150px", width: "200px"}}/>

        <div style={{display: 'flex', maxWidth: '167px'}}>
          {measurementUnit === 'cm' &&
            <TextField
              variant="standard" 
              label="Page height" 
              inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
              color="primary"
              type="number"
              step='1'
              value={Height.valueCM}
              onChange={Height.onChangeCM}
              error={!Height.isValidCM()}
              InputLabelProps={{sx: {"&.MuiInputLabel-root": { overflow: "visible" }}}}
              helperText={!Height.isValidCM() && "That value is invalid"}
            />}

          {measurementUnit === 'inch' &&
            <>
              <TextField
                variant="standard" 
                label="Page height"
                inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
                color="primary"
                type="number"
                step='1'
                value={Height.valueInchPrimary}
                onChange={Height.onChangeInchPrimary}
                error={!Height.isValidInch()}
                InputLabelProps={{sx: {"&.MuiInputLabel-root": { overflow: "visible" }}}}
                helperText={!Height.isValidInch() && "That value is invalid"}
              />
              <TextField
                select
                variant="standard"
                label=" " 
                value={Height.valueInchFraction}
                onChange={Height.onChangeInchFraction}
                style={{minWidth: "35px"}}>
                {FRACTIONS.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </>}
          <TextField
            select
            variant="standard"
            label=" " 
            value={measurementUnit}
            onChange={onChangeMeasurementUnit}
            style={{minWidth: "55px"}}>
            {MEASUREMENT_UNITS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {
                  <Typography
                    style={{fontSize: "0.75rem"}}
                  >{option.label}</Typography>
                }
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
        <img src={process.env.PUBLIC_URL + '/Pages.png'} alt="" style={{height: "150px", width: "200px"}}/>
        <TextField 
            variant="standard" 
            label="Last odd page"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            color="primary"
            type="number"
            step='1'
            value={LastOddPage.value}
            onChange={LastOddPage.onChange}
            error={!LastOddPage.isValid()}
            helperText={!LastOddPage.isValid() && "That value is invalid"}/>
      </div>

      <div style={{flexGrow: 1}}/>

      <div style={{display: "flex", gap: "16px"}}>
        <Button variant="outlined" onClick={onBackClick}>
          {'Back'}
        </Button>
        <Button variant="contained" style={{boxShadow: 'none'}} disabled={!Height.isValidCM() || !LastOddPage.isValid()} onClick={onNextClick}>
          {'Next'}
        </Button>
      </div>
    </div>
  );
};

const PrintPattern = (props) => {
  const {onResetClick, onBackClick} = props;

  return (
    <>
      <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", minHeight: "300px", borderRadius: "15px", backgroundColor: "#FEFEFF", padding: "16px"}}>
        <div style={{flexGrow: 1}}/>
        <SubscribedCheck subscribed={<PrintPatternButton isSubscribed={true} {...props} />} notSubscribed={<PrintPatternButton isSubscribed={false} {...props} />} />
        <div style={{flexGrow: 1}}/>
        <div style={{display: "flex", gap: "16px"}}>
          <Button variant="outlined" onClick={onBackClick}>
            {'Back'}
          </Button>
          <Button variant="outlined" onClick={onResetClick}>
            {'Reset'}
          </Button>
        </div>
      </div>
    </>
  );
};

const PrintPatternButton = (props) => {
  const {imagePrimaryDataURL, imageSecondaryDataURL, height, lastOddPage, isSubscribed, isInkSaver, angleLine} = props;
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [url, setUrl] = React.useState(null);

  const openPattern = (safeURL) => {
    if (isMobile) {
      var newWin = window.open(safeURL);
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') { 
          alert('Please disable your popup blocker and try again.');
      }
    } else {
      setShowModal(true);
    }
  };

  const PatternModal = () => {
    return (
      <>
        {showModal && 
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
          <ModalDialog layout="fullscreen" style={{backgroundColor: 'transparent'}}>
            <Sheet
              variant="soft"
              sx={{
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
              }}
            >
              <ModalClose variant="plain" sx={{ m: 1 }} />
              <div style={{display: 'flex', alignItems: 'end', gap: '5px'}}>
                <img src={process.env.PUBLIC_URL + '/Logo.png'} alt="Lotus Book Art" style={{maxWidth: "50px", maxHeight: "50px"}} />
                <Typography gutterBottom>
                  Here is your{!isSubscribed && " partial"} Fore Edge Book Art pattern.
                </Typography>
                <Link href={url} underline="hover" download gutterBottom>Click here to download</Link>
              </div>
              <iframe 
                src={url} 
                style={{
                    height: '80vh',
                    width: '100%'
                }}/>
            </Sheet>
          </ModalDialog>
        </Modal>}
      </>
    );
  };

  const onGetPatternClick = () => {
    if (url) {
      openPattern(url)
    } else {
      setLoading(true);
      GetPattern({angleLine, isInkSaver, imagePrimaryDataURL, imageSecondaryDataURL, height, lastOddPage, callback: (patternURL)=>{
        setUrl(patternURL);
        openPattern(patternURL)
        setLoading(false);
      }, subscribed: isSubscribed});
    }
  };

  if (isSubscribed) {
    return (
      <>
        <Typography variant="h4" gutterBottom component="div">
          Your pattern is ready!
        </Typography>
        <Button 
          variant="contained" 
          size="large" 
          color="success"
          disabled={loading}
          onClick={onGetPatternClick}
          endIcon={loading ? <CircularProgress style={{height: "20px", width: "20px"}} /> : <DownloadIcon />}>
            Open
        </Button>
        <PatternModal />
      </>
    );
  } else {
    return (
      <>
        <Subscribe>
          <Tooltip title="Only part of your pattern will be generated">
              <Button
                variant="text" 
                size="medium" 
                disabled={loading} 
                onClick={onGetPatternClick}>
                  No thanks
              </Button>
          </Tooltip>
        </Subscribe>
        <PatternModal />
      </>
    );
  }
};

export default Experience;